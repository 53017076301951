var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer-container" },
    [
      _c("h3", { staticClass: "drawer-title" }, [_vm._v("系统布局配置")]),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("开启 TopNav")]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.topNav,
              callback: function ($$v) {
                _vm.topNav = $$v
              },
              expression: "topNav",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("开启 Tags-Views")]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.tagsView,
              callback: function ($$v) {
                _vm.tagsView = $$v
              },
              expression: "tagsView",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("固定 Header")]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.fixedHeader,
              callback: function ($$v) {
                _vm.fixedHeader = $$v
              },
              expression: "fixedHeader",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("显示 Logo")]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.sidebarLogo,
              callback: function ($$v) {
                _vm.sidebarLogo = $$v
              },
              expression: "sidebarLogo",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v("动态标题")]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.dynamicTitle,
              callback: function ($$v) {
                _vm.dynamicTitle = $$v
              },
              expression: "dynamicTitle",
            },
          }),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-button",
        {
          attrs: {
            size: "small",
            type: "primary",
            plain: "",
            icon: "el-icon-document-add",
          },
          on: { click: _vm.saveSetting },
        },
        [_vm._v("保存配置")]
      ),
      _c(
        "el-button",
        {
          attrs: { size: "small", plain: "", icon: "el-icon-refresh" },
          on: { click: _vm.resetSetting },
        },
        [_vm._v("重置配置")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }