<template>
  <div :class="classObj" class="app-wrapper" :style="{'--current-color': theme}">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
    <sidebar class="sidebar-container" />
    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel>
        <settings />
      </right-panel>
    </div>
  </div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
import variables from '@/assets/styles/variables.scss'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      theme: state => state.settings.theme,
      sideTheme: state => state.settings.sideTheme,
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    variables() {
      return variables;
    }
  },
  mounted() {
   this.listenNotifyPublish();
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    //加入右下角通知websocket
    listenNotifyPublish() {
      const h = this.$createElement;
      let that = this
      let websocket
      let { userId, deptId } = this.$store.state.user
      if ('WebSocket' in window) {
        let socketBaseUrl = process.env.NODE_ENV === 'development' ? 'ws://127.0.0.1:8080' : process.env.VUE_APP_WEB_DOMAIN.replace('https', 'wss') + process.env.VUE_APP_BASE_API
        websocket = new WebSocket(`${socketBaseUrl}/bottom-notify/${deptId}/${userId}`)
      } else {
        console.log('您的浏览器不支持websocket')
        return
      }
      // 定义心跳消息
      const heartbeatMessage = 'web_socket_client_heart_beat';
      // 定义心跳间隔时间（毫秒）
      const heartbeatInterval = 5000;
      let heartbeatTimer;


      //连接发生错误的回调方法
      websocket.onerror = function(event) {
        console.log(event)
      }

      //连接成功建立的回调方法
      websocket.onopen = function(event) {
        startHeartbeat();
        console.log('链接上了右下角通知websocket')
      }

      //接收到消息的回调方法
      websocket.onmessage = function(event) {
        // 重置心跳定时器
        resetHeartbeat();
        // console.log('接受到了右下角通知消息', event.data)

        if(event.data !== 'web_socket_server_heart_beat'){
          let notify = JSON.parse(event.data)
          let goPath
          let title
          let nType
          let message
          if (notify.notifyType === 'record') {
            title = '扫码结果'
            goPath = '/resident-code/record'
            if (notify.msg.indexOf('success') !== -1){
              nType= 'success'
              message = h('div', [
                h('strong', [ '您收到一条',h('span',{ style: 'color: green'}, `扫码成功`),'的消息：']),
                h('i',{ style: 'color: green'}, [h('strong', `扫码成功,请点击查看`)])
              ])
            }else {
              nType ='error'
              message = h('div', [
                h('strong', [ '您收到一条',h('b',{ style: 'color: red'}, `扫码失败`),'的消息：']),
                h('i',{ style: 'color: red'}, [ h('strong', `${notify.msg},请点击查看！！！`)])
              ])
            }

          }else if(notify.notifyType === 'auth-record'){
            title = '发起证照授权'
            goPath = '/resident-code/auth-record'
            if (notify.msg.indexOf('success') !== -1){
              nType= 'success'
              message = h('div', [
                h('strong', [ '您收到一条',h('span',{ style: 'color: green'}, `${title}成功`),'的消息：']),
                h('i',{ style: 'color: green'}, [h('strong', `发起成功,等待授权`)])
              ])
            }else {
              nType ='error'
              message = h('div', [
                h('strong', [ '您收到一条',h('b',{ style: 'color: red'}, `${title}失败`),'的消息：']),
                h('i',{ style: 'color: red'}, [ h('strong', `${notify.msg},重新扫码或者联系管理员`)])
              ])
            }
          }else if(notify.notifyType === 'auth-result-record'){
            title = '证照授权结果'
             goPath = '/resident-code/auth-record'
          if (notify.msg.indexOf('SUCCESS') !== -1){
            nType= 'success'
            message = h('div', [
              h('strong', [ '您收到一条',h('span',{ style: 'color: green'}, `证照授权成功`),'的消息：']),
              h('i',{ style: 'color: green'}, [h('strong', `授权成功,请点击查看电子证照`)])
            ])
          }else if(notify.msg.indexOf('WAIT') !== -1) {
            nType ='warning'
            message = h('div', [
              h('strong', [ '您收到一条',h('b',{ style: 'color: #ffba00'}, `证照授权等待`),'的消息：']),
              h('i',{ style: 'color: #ffba00'}, [ h('strong', `请提醒居民刷脸授权然后点击刷新授权`)])
            ])
          }else if(notify.msg.indexOf('FAIL') !== -1) {
            nType ='error'
            message = h('div', [
              h('strong', [ '您收到一条',h('b',{ style: 'color: red'}, `证照授权失败`),'的消息：']),
              h('i',{ style: 'color: red'}, [ h('strong', `请提醒居民重新扫码发起授权`)])
            ])
          }
        }
          //发送通知
          that.$notify({
            title: title,
            // showClose: false,
            position: 'bottom-right',
            message: message,
            type: nType,
            duration: 10000,
            onClick() {
               that.$router.push({
                 path: goPath
               })
            }
          })

        }/*else{
          that.$notify({
            title: "心跳消息",
            // showClose: false,
            dangerouslyUseHTMLString:true,
            position: 'bottom-right',
            message: `<strong><i>${event.data}</i></strong>`,
            type: 'error',
            duration: 10000,
            onClick() {
              //去到相应的路径[暂时去除后续还有其他记录（电子证照授权记录）]
              /!* that.$router.push({
                 path: goPath
               })*!/
            }
          })
        }*/
      }

      //连接关闭的回调方法
      websocket.onclose = function(){
        stopHeartbeat();
        console.log("关闭了右下角websocket...");
      }

      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = function(){
        websocket.close();
      }
      // 启动心跳定时器
      function startHeartbeat() {
        heartbeatTimer = setInterval(function() {
          // 发送心跳消息
          websocket.send(heartbeatMessage);
        }, heartbeatInterval);
      }

      // 重置心跳定时器
      function resetHeartbeat() {
        clearInterval(heartbeatTimer);
        startHeartbeat();
      }

      // 停止心跳定时器
      function stopHeartbeat() {
        clearInterval(heartbeatTimer);
      }
    }

  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
