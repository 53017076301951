import Vue from "vue";

import Cookies from "js-cookie";
/*新大屏组件和字体*/
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import "@/assets/font/font.css";//字体
import Element from "element-ui";
import "./assets/styles/element-variables.scss";

import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/lshf.scss"; // ruoyi css
import App from "./App";
import store from "./store";
import router from "./router";
import directive from "./directive"; //directive

import { VueJsonp } from "vue-jsonp";

import "./assets/icons"; // icon
import "./permission"; // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  download,
  handleTree,
} from "@/utils/lshf";
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar";
// 富文本组件
import Editor from "@/components/Editor";
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
// 文件上传组件
import FileUpload from "@/components/FileUpload";
// 图片上传组件
import ImageUpload from "@/components/ImageUpload";
// 字典标签组件
import DictTag from "@/components/DictTag";
// 头部标签组件
import VueMeta from "vue-meta";
import watermark from '@/utils/warterMark'



// 全局方法挂载
/*水印*/
Vue.prototype.watermark = watermark
Vue.prototype.getDicts = getDicts;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.download = download;
Vue.prototype.handleTree = handleTree;
Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
};

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
};

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};
Vue.prototype.msgWarn = function (msg) {
  this.$message.warning(msg);
};


// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)


Vue.use(directive);
Vue.use(VueMeta);
Vue.use(VueJsonp);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
Element.Dialog.props.closeOnClickModal.default = false; //设置全局弹窗点击遮罩层不可关闭 防止误关
Vue.use(Element, {
  size: Cookies.get("size") || "mini", // set element-ui default size
});

Vue.config.productionTip = false;

// 过滤器
import * as custom from './utils/filter'
Object.keys(custom).forEach(key => {
    Vue.filter(key, custom[key])
});

Vue.mixin({
  methods: {
    test() {},
  },
});
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
