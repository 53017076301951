var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      attrs: { trigger: "hover", "show-timeout": 30, "hide-on-click": false },
      on: { command: _vm.goMsgPage },
    },
    [
      _c("div", [
        _c("i", { staticClass: "el-icon-message-solid" }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.unReadMsgCnt != "0",
                expression: "unReadMsgCnt!='0'",
              },
            ],
            staticClass: "label-danger",
          },
          [_vm._v(_vm._s(_vm.unReadMsgCnt))]
        ),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.unReadMsgCnt != "0",
                  expression: "unReadMsgCnt!='0'",
                },
              ],
              staticStyle: { "text-align": "center" },
              attrs: { command: "setAllRead" },
            },
            [_vm._v(" 全部设为已读 ")]
          ),
          _vm._l(_vm.msgList, function (item, key) {
            return _c(
              "el-dropdown-item",
              {
                key: key,
                attrs: {
                  command: { msg: item, index: key },
                  icon:
                    item.isRead == "1"
                      ? "el-icon-bell"
                      : "el-icon-message-solid",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      (item.isRead == "1" ? "【已读】" : "【未读】") +
                        item.msgContent
                    ) +
                    " "
                ),
              ]
            )
          }),
          _c(
            "el-dropdown-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLastPage,
                  expression: "!isLastPage",
                },
              ],
              staticStyle: { "text-align": "center" },
              attrs: { command: "viewMoreMsg" },
            },
            [_vm._v(" 查看更多消息> ")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }