<template>
  <component :is="type" v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script>
import { isExternal } from '@/utils/validate'
import { aesEncrypt } from '@/utils/crypto-js'

export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.to)
    },
    type() {
      if (this.isExternal) {
        return 'a'
      }
      return 'router-link'
    }
  },
  methods: {
    linkProps(to) {
      //对外链地址处理(鲁通码)
      if (this.isExternal) {
        if(this.to.lastIndexOf('login-by-zhsq') !== -1){
          //登录参数加密后进行跳转
          to = to + `?loginParam=${aesEncrypt('lshc5678881')}`
        }
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {
        to: to
      }
    }
  }
}
</script>
