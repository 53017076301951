import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}



// 通过威海市指挥中心token登录
export function loginByWeihaiCommand(commandToken) {
  return request({
    url: '/login-by-weihai-command',
    method: 'post',
    params: { commandToken }
  })
}
//通过智慧社区登录
export function loginByZhsq(loginParam) {
  return request({
    url: '/login-by-zhsq',
    method: 'post',
    params: { loginParam }
  })
}




// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}
