import Vue from 'vue'
import Router from 'vue-router'
import { getConfigKey } from '@/api/system/config'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'   // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [

  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },

  {
    path: '/register',
    component: (resolve) => require(['@/views/register'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user' }
      }
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'role/:userId(\\d+)',
        component: (resolve) => require(['@/views/system/user/authRole'], resolve),
        name: 'AuthRole',
        meta: { title: '分配角色', activeMenu: '/system/user'}
      }
    ]
  },
  {
    path: '/resident-code/place-add',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'placeUser',
        component: (resolve) => require(['@/views/business/residentCode/place/place-add.vue'], resolve),
        name: 'PlaceAdd',
        meta: { title: '添加白名单', activeMenu: '/resident-code'}
      }
    ]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: (resolve) => require(['@/views/system/role/authUser'], resolve),
        name: 'AuthUser',
        meta: { title: '分配用户', activeMenu: '/system/role'}
      }
    ]
  },
  {
    path: '/system/dict-data',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: (resolve) => require(['@/views/system/dict/data'], resolve),
        name: 'Data',
        meta: { title: '字典数据', activeMenu: '/system/dict'}
      }
    ]
  },
  {
    path: '/monitor/job-log',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/monitor/job/log'], resolve),
        name: 'JobLog',
        meta: { title: '调度日志', activeMenu: '/monitor/job'}
      }
    ]
  },
  {
    path: '/tool/gen-edit',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index/:tableId(\\d+)',
        component: (resolve) => require(['@/views/tool/gen/editTable'], resolve),
        name: 'GenEdit',
        meta: { title: '修改生成配置', activeMenu: '/tool/gen'}
      }
    ]
  },
  {
    path: '/cm/work-order-detail',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index/:workOrderId(\\w+)',
        component: (resolve) => require(['@/views/business/work-order/detail'], resolve),
        name: 'WorkOrderDetail',
        meta: { title: '工单详情', activeMenu: '/business/work-order'}
      }
    ]
  },
  {
    path: '/business/mission-process',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index/:missionId(\\d+)',
        component: (resolve) => require(['@/views/business/missionProcess/index'], resolve),
        name: 'Data',
        meta: { title: '任务进度', activeMenu: '/business/missionProcess'}
      }
    ]
  },
/*      {
        path: '/video/preview/index',
        component: Layout,
        hidden: false,
        children: [
          {
            path: '/video/preview/index',
            component: (resolve) => require(['@/views/video/playback/index'], resolve),
            name: 'playback',
             meta: { title: '视频回放' }
          }
        ]
      },
  {
    path: '/safe/video/preview',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/safe/video/preview',
        component: (resolve) => require(['@/views/safe/video/preview'], resolve),
        name: 'safe-video-preview',
        meta: { title: '视频预览', activeMenu: '/safe/video'}
      }
    ]
  }*/
]
const router =  new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

//其他不相同页面
  let diffPath=  process.env.VUE_APP_DIFF_FILES_DIR
  router.addRoutes([
    {
    path: '/login',
    component: (resolve) => require(['@/views'+ diffPath +'/login'], resolve),
    hidden: true
    },
    {
      path: '/login-by-weihai-command',
      component: (resolve) => require(['@/views'+ diffPath +'/login-by-weihai-command'], resolve),
      hidden: true
    },
    {
      path: '/login-by-zhsq',
      component: (resolve) => require(['@/views'+ diffPath +'/login-by-zhsq'], resolve),
      hidden: true
    },
    {
      path: '/index',
      redirect:'/resident-code/record'
    },
    {
      path: '/',
      redirect:'/resident-code/record'
    },
    {
      path: '/pop/pop',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/pop'], resolve),
      name: 'onemap',
      meta: { title: '一张图', activeMenu: ''+ diffPath +'/pop/pop'}
    },
    {
      path: '/pop/building',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/building'], resolve),
      name: 'building',
      meta: { title: '智慧楼宇', activeMenu: ''+ diffPath +'/pop/building'}
    },
    {
      path: '/pop/map',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/map'], resolve),
      name: 'map',
      meta: { title: '社区地图', activeMenu: ''+ diffPath +'/pop/map'}
    },
    {
      path: '/pop/louyu',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/louyu'], resolve),
      name: 'louyu',
      meta: { title: '楼宇详情', activeMenu: ''+ diffPath +'/pop/louyu'}
    },
    {
      path: '/pop/louyunew',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/louyunew'], resolve),
      name: 'louyunew',
      meta: { title: '楼宇详情', activeMenu: ''+ diffPath +'/pop/louyunew'}
    },
    {
      path: '/pop/user-portrait',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/user-portrait'], resolve),
      name: '用户画像',
      meta: { title: '用户画像', activeMenu: ''+ diffPath +'/pop/user-portrait'}
    },
    {
      path: '/pop/community',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/community'], resolve),
      name: '小区详情',
      meta: { title: '小区详情', activeMenu: ''+ diffPath +'/pop/community'}
    },
    {
      path: '/pop/community-service',
      hidden: true,
      component: (resolve) => require(['@/views'+ diffPath +'/pop/community-service'], resolve),
      name: '社区服务',
      meta: { title: '社区服务', activeMenu: ''+ diffPath +'/pop/community-service'}
    }
    ])
export default router;
