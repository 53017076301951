import moment from 'moment';
/**
 * @param  value  时间格式字符串 2020-10-25 09:30:00
 * @param  format  格式类型 yyyy-mm-dd
 */
const formatDate = (value, format) => {
  return moment(value).format(format)
};

const parseDate = (dateString) =>{
  return moment(dateString).toDate()
}
/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp) => {
  let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10 ?
      '0' + (date.getMonth() + 1) :
      date.getMonth() + 1) + '-'
  let D =
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  let h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  let m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  let s =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // return Y + M + D + h + m + s
  return Y + M + D
};

/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToSecond = (timestamp) => {
  let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10 ?
      '0' + (date.getMonth() + 1) :
      date.getMonth() + 1) + '-'
  let D =
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  let h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  let m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  let s =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
};

//时间转换(js将 “2021-07-06T06:23:57.000+00:00” 转换为年月日时分秒)
function transformTimestamp(timestamp) {
  let a = new Date(timestamp).getTime();
  const date = new Date(a);
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  const dateString = Y + M + D + h + m + s;
  return dateString;
}

//0/普通卡;1/VIP卡;2/来宾卡;3/巡逻卡;6/巡检卡;11/管理员卡
function filterCardType(type) {
  type = Number(type || 0);
  switch (type) {
    case 0:
      return "普通卡";
    case 1:
      return "VIP卡";
    case 2:
      return "来宾卡";
    case 3:
      return "巡逻卡";
    case 6:
      return "巡检卡";
    case 11:
      return "管理员卡";
    default:
      return "未知";
  }
}

//远程 : 48 刷卡 : 49,51,52 人脸 : 61,62
function filterThroughType(type) {
  type = Number(type || 0);
  switch (type) {
    case 48:
      return "远程";
    case 49:
    case 51:
    case 52:
      return "刷卡";
    case 61:
    case 62:
      return "人脸";
    default:
      return "未知";
  }
}

/**
 * 存储localStorage
 */
const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
const getStore = name => {
  if (!name) return;
  return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
const removeStore = name => {
  if (!name) return;
  window.localStorage.removeItem(name);
}

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
  let date = new Date();
  date.setDate(date.getDate() + day);
  document.cookie = name + '=' + value + ';expires=' + date;
};

/**
 * 获取cookie
 **/
function getCookie(name) {
  let reg = RegExp(name + '=([^;]+)');
  let arr = document.cookie.match(reg);
  if (arr) {
    return arr[1];
  } else {
    return '';
  }
};

/**
 * 删除cookie
 **/
function delCookie(name) {
  setCookie(name, null, -1);
};

/**
 * 导出
 **/
export {
  formatDate,
  parseDate,
  transformTimestamp,
  filterThroughType,
  timestampToTime,
  timestampToSecond,
  filterCardType,
  setStore,
  getStore,
  removeStore,
  setCookie,
  getCookie,
  delCookie,
}
