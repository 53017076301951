var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
      style: {
        backgroundColor:
          _vm.sideTheme === "theme-dark"
            ? _vm.variables.menuBg
            : _vm.variables.menuLightBg,
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _c(
            "router-link",
            {
              key: "expand",
              staticClass: "sidebar-logo-link",
              attrs: { to: "" },
            },
            [
              _c(
                "h1",
                {
                  staticClass: "sidebar-title",
                  style: {
                    color:
                      _vm.sideTheme === "theme-dark"
                        ? _vm.variables.sidebarTitle
                        : _vm.variables.sidebarLightTitle,
                  },
                },
                [_vm._v(_vm._s(_vm.title) + " ")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }