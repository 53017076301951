import CryptoJS from "crypto-js";

/**
 * 加密(AES)
 */
export const aesEncrypt = (word) => {
  if(!word) return word;//为空的话原封不动返回
  var key = CryptoJS.enc.Utf8.parse("WDTjc7MuhQPjlhP2");
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}


export const encryptWhenGet = (word) => {
  return encodeURIComponent(aesEncrypt(word));
}
/**
 * 解密(AES)
 */
export const aesDecrypt = (word) => {
  if(isEmpty(word)) return word;//为空的话原封不动返回
  var key = CryptoJS.enc.Utf8.parse("WDTjc7MuhQPjlhP2");
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
