// 查询三务公开列表
import request from '@/utils/request'

export function listUserMsg(query) {
  return request({
    url: '/system/usermsg/list',
    method: 'post',
    params: query
  })
}


export function setAllRead(data) {
  return request({
    url: '/system/usermsg/set-all-read',
    method: 'post',
    params: data
  })
}



export function edituserMsg(data) {
  return request({
    url: '/system/usermsg/edit',
    method: 'post',
    params: data
  })
}

