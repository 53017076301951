<template>
  <el-dropdown trigger="hover" :show-timeout="30" @command="goMsgPage" :hide-on-click="false" >
    <div>
      <i class="el-icon-message-solid"/><span v-show="unReadMsgCnt!='0'" class="label-danger">{{unReadMsgCnt}}</span>
    </div>
    <el-dropdown-menu slot="dropdown" >
      <el-dropdown-item v-show="unReadMsgCnt!='0'" command="setAllRead" style="text-align: center">
        全部设为已读
      </el-dropdown-item>
      <el-dropdown-item v-for="(item,key) of msgList" :key="key" :command="{msg:item,index:key}"
                        :icon="item.isRead == '1' ?'el-icon-bell':'el-icon-message-solid'">
        {{
          (item.isRead == '1' ? '【已读】' : '【未读】') + item.msgContent
        }}
      </el-dropdown-item>
      <el-dropdown-item v-show="!isLastPage" command="viewMoreMsg"style="text-align: center">
        查看更多消息>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { edituserMsg, listUserMsg, setAllRead } from '@/api/business/usermsg'

export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 5,
      isLastPage: false,
      unReadMsgCnt: '0',
      msgList: [],
      hideOnClick: false,
      timer:null
    }
  },
  mounted() {
    this.reloadMsg()
    this.timer =  setInterval(this.reloadMsg, 60000)
  },
  beforDestroy(){
    if(this.timer){
      clearInterval(this.timer)
    }
  },
  computed: {},
  methods: {
    reloadMsg() {
      this.msgList = []
      this.pageNum = 1
      this.isLastPage = false
      this.getSysUserMsgList()

    },
    getSysUserMsgList() { //获取消息列表
      listUserMsg({ pageNum: this.pageNum, pageSize: this.pageSize }).then((res) => {
        if (res.code == 200) {
          this.msgList = this.msgList.concat(res.rows)
          if (res.rows.length < this.pageSize) {
            this.isLastPage = true
          }
          this.unReadMsgCnt = res.msg
        }
      })
    },
    viewMoreMsg() {
      this.pageNum++
      this.getSysUserMsgList()
    },

    setAllRead() {//全部设置为已读
      setAllRead().then((res) => {
        this.reloadMsg()
      })

    },
    goMsgPage(value) {
      if (typeof value == 'string') { //查看更多消息
        this[value]()
      } else {
        // 跳转
        let msg = value.msg
        let index = value.index

        if (msg.isRead == '0') {
          edituserMsg({ msgId: msg.msgId, isRead: 1 }).then(res => {
            if (res.code == 200) {
              this.unReadMsgCnt = parseInt(this.unReadMsgCnt) - 1
              msg.isRead = '1'
              this.msgList[index] = msg
            }
          })
        }
        this.$router.push({ path: msg.menuUrl, query: JSON.parse(msg.actionJsonStr) })
      }

    }
  }

}
</script>
<style scoped>
.label-danger {
  color: #cd1c1c;
  font-size: medium;
  font-weight: bold;
  position: relative;
  bottom: 10px;

}


</style>
