<template>
  <div>
<!--    showAll为false时，默认显示一个图片-->
    <div v-if="src && !showAll">
      <el-image
        :src="`${realSrc}`"
        fit="cover"
        :style="`width:${realWidth};height:${realHeight};`"
        :preview-src-list="previewSrcList"
      >
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
<!--    showAll为true时，可显示所有图片-->
    <div v-if="src && showAll">
      <el-image
        v-for="(file,index) in src.split(',')"
        :key="index"
        :src="baseImgUrl + formatImgSrc(file)"
        fit="cover"
        :style="`width:${realWidth};height:${realHeight};margin-right:10px`"
        :preview-src-list="previewSrcList"
      >
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
  </div>
</template>

<script>
import {isExternal} from "@/utils/validate";

export default {
  name: "ImagePreview",
  props: {
    src: {
      required: true,
      default: ""
    },
    baseImgUrl: {
      type:  String,
      default: process.env.VUE_APP_OBS_BASE_API
    },
    width: {
      type: [Number, String],
      default: ""
    },
    height: {
      type: [Number, String],
      default: ""
    },
    showAll: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'lfit'
    },
    isSlash: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    realSrc() {
      if(this.src==null ||this.src==undefined){
        return ''
      }
      let real_src = this.src.split(",")[0];
      if (isExternal(real_src)) {
        return real_src;
      }
      let formatSrc = this.formatImgSrc(real_src)
      return this.baseImgUrl + formatSrc;
    },
    previewSrcList() {
      if(this.src==null ||this.src==undefined){
        return null
      }
      let preview_src_list = this.src.split(",");
      let srcList = [];
      preview_src_list.forEach(item => {
        if (isExternal(item)) {
          return srcList.push(item);
        }
        return srcList.push(this.baseImgUrl + item);
      });
      return srcList;
    },
    realWidth() {
      return typeof this.width == "string" ? this.width : `${this.width}px`;
    },
    realHeight() {
      return typeof this.height == "string" ? this.height : `${this.height}px`;
    },

  },
  methods: {
    formatImgSrc(src) {
      return src + `?x-image-process=image/resize,m_${this.type},w_${this.width},h_${this.height}${this.isSlash ? '/imageslim' : ''}`;
    },
  }
};
</script>

<style lang="scss" scoped>
.el-image {
  border-radius: 5px;
  background-color: #ebeef5;
  box-shadow: 0 0 5px 1px #ccc;
  ::v-deep .el-image__inner {
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
  ::v-deep .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #909399;
    font-size: 30px;
  }
}
</style>
