let watermark = {}

let setWatermark = (text, sourceBody) => {

  let id = '1.23452384164.123412415'

  if (document.getElementById(id) !== null) {

    sourceBody.removeChild(document.getElementById(id))

  }

  let can = document.createElement('canvas')

  can.width = 200;
  can.height = 100;

  let cans = can.getContext('2d')

  cans.rotate(-30 * Math.PI / 180)

  cans.font = '15px Microsoft JhengHei '

  cans.fillStyle = '#666666'

  cans.textAlign = 'center'

  cans.textBaseline = 'Middle'



  cans.fillText(text, can.width / 10, can.height / 2);

  let water_div = document.createElement('div')

  water_div.id = id

  water_div.style.pointerEvents = 'none'

  water_div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'

  water_div.style.opacity = '0.15'

  water_div.style.position = 'fixed'

  water_div.style.zIndex = '10000000000'

  water_div.style.top = '10%'


  water_div.style.width = document.documentElement.clientWidth  + 'px'

  water_div.style.height = document.documentElement.clientHeight  + 'px'

  //通过类选择器方式
/*
    document.body.appendChild(water_div)
*/
  // 通过组件ref

    sourceBody.appendChild(water_div)


  return id

}

/**

 *  该方法只允许调用一次

 *  @param:

 *  @text == 水印内容

 *  @sourceBody == 水印添加在哪里，不传就是body

 * */

watermark.set = (text, sourceBody) => {
  console.log("开始设置水印.....")
  watermark.remove();

  setWatermark(text, sourceBody)


   window.onresize = () => {
      watermark.set(text, sourceBody)
   }
}
// 移除水印方法
watermark.remove = () => {
  let id = '1.23452384164.123412415'
  if (document.getElementById(id) !== null) {
    document.getElementById(id).style.display = 'none'
  }
}
export default watermark
